<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'BlankLayout',
  mounted () {
    core.mainIndex()
  }
}
</script>
